import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from "../components/footer"

import Tarot from "../images/consejeria.jpg"

const Services = () => {
  return (
    <Layout>
      <SEO title="Gracias por contactar a Spiritual Worker" />

      <div className="w-full mx-auto bg-gray-900 pt-5 pt-10 pb-10 lg:pt-20 lg:pb-20">
        <h2 className="titles text-gray-200 text-2xl lg:text-4xl text-center pb-5 lg:pb-20">
          ¡Gracias!
        </h2>
        <div className="w-full lg:w-10/12 lg:flex mx-auto">
          <div className="hidden md:block w-full lg:w-1/3 rounded-lg p-5">
            <img src={Tarot} alt="Contacto de Spiritual Worker" className="" />
            <p className="text-sm text-yellow-100 p-0 py-4">
              &ldquo;Practica meditación. Descubrirás que llevas en tu corazón
              un paraíso portátil.&rdquo; - Yogananda
            </p>
          </div>

          <div className="w-full lg:w-2/3 mt-5 lg:mt-0">
            <p>
              Hemos recibido su mensaje enviado por el formulario. Le
              responderemos con la mayor prontitud posible.
            </p>
            <p>
              Recuerde que siempre se puede poner en contacto con Spiritual
              Worker por medio de Whatsapp escribiendo al{" "}
              <a
                className="whatsapp underline"
                href="https://api.whatsapp.com/send?phone=50688355826&text=Contacto%20del%20sitio%20web"
              >
                +506 8835 5826
              </a>
              , o enviando un email a{" "}
              <a className="underline" href="mailto:info@spiritualworker.org">
                info@spiritualworker.org
              </a>
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </Layout>
  )
}

export default Services
